// export const config = {
//     API_URL: 'https://staging.odio.com.ng/v1',
//     TOKEN_NAME: 'ODM_Token'
// }

const prodConfig = {
  API_URL: "https://api.odiopay.com/api/v1",
  BASE_URL: "https://api.odiopay.com/api/v1"
}

const devConfig = {
  // API_URL: "http://localhost:8000/api",
  // BASE_URL: "http://localhost:8000/api"
  // API_URL: "https://odiopay-staging-be.onrender.com/api/v1",
  // BASE_URL: "https://odiopay-staging-be.onrender.com/api/v1"
  API_URL: "https://api.odiopay.com/api/v1",
  BASE_URL: "https://api.odiopay.com/api/v1"
}

const generalConfig = {
  PASSWORD_PATTERN:
    "^(?=.*[A-Za-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*#?&])[A-Za-z\\d@$!%*#?&\\.]{8,}$",
  TOKEN_NAME: "ODM_Token"
}
export const config = {
  ...generalConfig,
  ...(process.env.REACT_APP_ENV === "production" ? prodConfig : devConfig)
}

export const paystackConfig = {
  // ptk: "sk_test_b2d3e9c82c0c03f34b0661ad09a1522b28e378c4",
  ptk: "sk_live_b79ca5077a3bbedc144afb72aa802e3e3693fe46",
  // ptk: "sk_test_7505bfbed8762ae3b5035ac53750a3a358bf7d9f",
  url: "https://api.paystack.co"
}
