import React, {
  useContext,
  useEffect,
} from 'react';

import { useHistory } from 'react-router-dom';

import Loader from '../../components/loader';
import Main from '../../components/main';
import { UserContext } from '../../contexts/user_context';
import { useApiCall } from '../../hooks/api_call_hooks';
import { useObjectData } from '../../hooks/test_hooks';

const stopper_data = [
    {
        title: "Complete and verify your business profile",
        subtitle: "Submit business details to accept live payments",
        button_text: "Complete business details",
        action: "business",
        key: "has_business",
    },
    {
        title: "Setup your Odiopay store",
        subtitle: "Upload your products to the Odiopay store.",
        button_text: "Upload products",
        action: "open_store",
        key: "has_store",
    },
    {
        title: "Setup your payout method",
        subtitle: "Add your bank details to receive payment from Odiopay",
        button_text: "Add bank details",
        action: "add_bank",
        key: "has_bank_details",
    },
];

export function VerifyProcess() {
    const { user, fetchUserDetails } = useContext(UserContext);
    const callApi = useApiCall();
    const history = useHistory();
    const state = useObjectData({
        has_business: false,
        has_store: false,
        has_bank_details: false,
    });
    console.log(user);
    useEffect(() => {
        state.set({
            has_store: user?.has_store,
            has_bank_details: user?.has_bank_details,
            has_business: user?.has_business,
        });
    }, [user]);
    useEffect(() => {
        if (
            state.get.has_business &&
            state.get.has_store &&
            state.get.has_bank_details
        ) {
            history.push("/dashboard");
        }
    }, [state]);
    const handleAction = (action) => {
        switch (action) {
            case "business":
                history.push("/business/setup");
                break;
            case "open_store":
                history.push("/store/setup");
                break;
            case "add_bank":
                history.push("/business/setup?add_bank=true");
                break;
            default:
                return;
        }
    };

    return (
        <Main name="Get Started">
            <main className="container px-5 md:px-8 pt-8 mx-auto">
                {callApi.loading && <Loader />}
                <h6 className="text-2xl font-light text-primary-accent">
                    Welcome to Odiopay {user.first_name && `, ${user.first_name}`}!
                </h6>
                <div className="bg-white my-16">
                    <header className="px-8 py-5 text-k-purple text-base font-thin">
                        Get started by completing the following steps
                    </header>
                    <div className="grid lg:grid-cols-3">
                        {stopper_data.map((sd, i) => {
                            const active = state.get[sd.key];
                            const done = state.get[sd.key];
                            const bg = done
                                ? "bg-k10-gray"
                                : active
                                ? "bg-white"
                                : "bg-k8-gray";
                            const numberBg = done
                                ? "bg-k2-green border-k-green border-4"
                                : active
                                ? "bg-k-indigo"
                                : "bg-white border border-gray-500";
                            return (
                                <div
                                    key={sd.key}
                                    className={`${bg} flex md:px-10 py-10 flex-col items-center border h-full`}
                                >
                                    <span
                                        className={`${numberBg} ${
                                            active
                                                ? " text-white"
                                                : " text-gray-500"
                                        } rounded-full   p-2 h-10 w-10 flex justify-center items-center text-base font-bold`}
                                    >
                                        {done ? (
                                            <span className="text-white font-black text-xl">
                                                {" "}
                                                ✓{" "}
                                            </span>
                                        ) : (
                                            `${i + 1}`
                                        )}
                                    </span>
                                    <h4 className="text-k-indigo h-20 text-center mt-6 text-lg font-black">
                                        {sd.title}
                                    </h4>
                                    <p className="text-k2-purple h-16 mt-4 mb-32 text-center">
                                        {sd.subtitle}
                                    </p>
                                    <button
                                        className={`${
                                            active
                                                ? "text-k9-gray"
                                                : "text-k-indigo bg-k10-gray"
                                        } hover:shadow-md px-5 py-2 rounded font-semibold`}
                                        onClick={() => handleAction(sd.action)}
                                    >
                                        {sd.button_text}
                                    </button>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </main>
        </Main>
    );
}
