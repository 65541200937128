import React, { useEffect } from "react";

export function Modal({ children }) {
    return (
        <div className="fixed top-0 bottom-0 right-0 left-0 h-screen w-screen z-30 bg-black-op25 ">
            <div
                style={{
                    minWidth: "350px",
                    minHeight: "300px",
                    maxHeight: "80vh",
                    overflowX: "auto",
                }}
            >
                {children}
            </div>
        </div>
    );
}

export function useOutsideAlerter(ref, handler) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                handler();
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}

export function ApproveModal({
    close,
    message,
    title,
    buttonText = "Ok",
    extraButtonAction,
}) {
    return (
        <Modal>
            <div className="text-center absolute-center relative bg-primary text-white py-5 px-4 md:px-12 shadow-lg">
                <button
                    onClick={close}
                    className="absolute top-0 right-0 m-5 font-bold text-3xl rounded-full text-white text-base"
                >
                    &times;
                </button>
                <h5 className="whitespace-normal md:whitespace-no-wrap mt-6 mb-6 text-xl font-bold">
                    {title}
                </h5>
                <p className="mb-12">{message}</p>

                <div className="flex justify-center">
                    <button
                        onClick={extraButtonAction || close}
                        className=" border border-gray-300 px-10 py-3"
                    >
                        {buttonText}
                    </button>
                </div>
            </div>
        </Modal>
    );
}
