import "./styles/custom-small.css";
import "./styles/index.css";
import "react-tippy/dist/tippy.css";

import React, { lazy, Suspense } from "react";

import { positions, Provider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { FallBack } from "./components/fallback";
import { NetworkMonitor } from "./components/network_monitor";
import { UserProvider } from "./contexts/user_context";
import Login from "./pages/auth/login.js";
import Register from "./pages/auth/register.js";
import Main, { dashboardRoutes } from "./pages/main";

const options = {
    timeout: 10000,
    position: positions.TOP_CENTER,
    transition: "scale",
};

function App() {
    return (
        <Provider template={AlertTemplate} {...options}>
            <UserProvider>
                <NetworkMonitor />
                <Router>
                    <Suspense fallback={<FallBack />}>
                        <Switch>
                            <Route exact path="/login" component={Login} />
                            <Route
                                exact
                                path="/register"
                                component={Register}
                            />

                            <Route
                                exact
                                path="/verify/email"
                                component={lazy(() =>
                                    import("./pages/auth/verify_email")
                                )}
                            />
                            <Route
                                exact
                                path="/password/forgot"
                                component={lazy(() =>
                                    import("./pages/auth/forgot_password")
                                )}
                            />
                            <Route
                                exact
                                path="/password/reset"
                                component={lazy(() =>
                                    import("./pages/auth/password_reset")
                                )}
                            />
                            <Route
                                exact
                                path={[
                                    ...dashboardRoutes.map((v) => v.path),
                                    "/",
                                ]}
                                component={Main}
                            />
                            <Route
                                component={lazy(() =>
                                    import("./pages/pageNotFound")
                                )}
                            />
                        </Switch>
                    </Suspense>
                </Router>
            </UserProvider>
        </Provider>
    );
}

export default App;
