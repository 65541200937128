import React, {
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import Joyride, { STATUS } from 'react-joyride';
import { useHistory } from 'react-router-dom';

import { UserContext } from '../contexts/user_context';
import { apiCaller } from '../hooks/fetchHooks';
import { updateUser } from '../utils/api';
import {
  Modal,
  useOutsideAlerter,
} from './modal';

const title = (text) => (
    <h6 className="text-left text-k-indigo text-xs pl-2 font-black">{text}</h6>
);
const content = (text) => (
    <p className="text-k11-gray text-sm text-left">{text}</p>
);
const steps = [
    {
        target: "#profile-avatar",
        disableBeacon: true,
        title: (
            <h6 className="text-left text-k-indigo text-xs pl-2 font-black">
                Manage your profile details
            </h6>
        ),
        content: (
            <p className="text-k11-gray text-sm text-left">
                Update your profiles, change password, verify profile and manage
                other profile here.
            </p>
        ),
    },
    {
        target: "#dashboard",
        title: title("Dashboard"),
        content: content(
            "View general report of your transactions and customer activities"
        ),
        placement: "right",
    },
    {
        target: "#my-business",
        title: title("My Business"),
        content: content(
            <>
                Setup your business on Odiopay here. <br />
                Verify your business, setup bank accounts and manage your
                account Api keys.
            </>
        ),
        placement: "right",
    },
    {
        target: "#products",
        title: title("My Store"),
        content: content("Create, view, update and delete products here."),
        placement: "right",
    },
    {
        target: "#invoice",
        title: title("Invoice"),
        content: content(
            "Send a payment request to a customer from here. Also view payment status here"
        ),
        placement: "right",
    },
    {
        target: "#transactions",
        title: title("Transactions"),
        content: content(
            "View completed and on-going customer transactions here."
        ),
        placement: "right",
    },

    {
        target: "#withdraw",
        title: title("Withdraw"),
        content: content(
            "Request payment from your Odiopay to your bank account."
        ),
        placement: "right",
    },
    {
        target: "#payouts",
        title: title("Payouts"),
        content: content(
            "View all all payments to your bank account from Odiopay here."
        ),
        placement: "right",
    },
    {
        target: "#settings",
        title: title("Settings"),
        content: content(
            "Manage your account and payment method rules from here."
        ),
        placement: "top",
    },
    // {
    //     target: "#disputes",
    //     title: title("Disputes"),
    //     content: content(
    //         "View and respond to customer transaction disputes here."
    //     ),
    //     placement: "right",
    // },
    // {
    //     target: "#customers",
    //     title: title("Customers"),
    //     content: content("View and manage your customers here."),
    //     placement: "right",
    // },
  

    // {
    //     target: "#refunds",
    //     title: title("Refunds"),
    //     content: content(
    //         "View and manage refund payment request from your customers here."
    //     ),
    //     placement: "right",
    // },
    // {
    //     target: "#verify-profile",
    //     title: title("Verify profile"),
    //     content: content(
    //         "To start accepting Odiopay payments, you will need to verify your business account by providing the relevant documents and adding your BVN"
    //     ),
    //     placement: "right",
    // },
 
];

export function Onboarding() {
    const { user, fetchUserDetails, onboard } = useContext(UserContext);
    const [run, setRun] = useState(false);
    const ref = useRef();
    useOutsideAlerter(ref, () => onboard.set(false));
    const history = useHistory();
    useEffect(() => {
        if (user.id && !user.tip) {
            // TODO change to true
            onboard.set(true);
        }
    }, [user]);
    const handleJoyrideCallback = (data) => {
        const { action, status, type } = data;
        const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];
        if (finishedStatuses.includes(status)) {
            setRun(false);
            skipTip();
        }
    };
    const skipTip = () => {
        apiCaller(
            () => updateUser({ tip: true }),
            () => {
                fetchUserDetails();
            }
        );
    };
    return (
        <div>
            {onboard.get && (
                <Modal>
                    <div className="absolute-center">
                        <div
                            ref={ref}
                            className="rounded bg-white p-5 text-k-indigo"
                        >
                            <h6 className="font-bold text-sm text-center">
                                New to Odiopay
                            </h6>
                            <p className="mt-4 mb-8 text-base text-k11-gray">
                                Let's show you around on how best to navigate on
                                Odiopay
                            </p>
                            <div className="flex items-center justify-center space-x-6">
                                <button
                                    onClick={() => {
                                        onboard.set(false);
                                        skipTip();
                                    }}
                                >
                                    Skip
                                </button>
                                {/* <button
                                    onClick={() => {
                                        setShowModal(false);
                                    }}
                                >
                                    Not now
                                </button> */}
                                <button
                                    onClick={() => {
                                        setRun(true);
                                        onboard.set(false);
                                    }}
                                    className="font-bold"
                                >
                                    OK, Show me
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>
            )}
            <Joyride
                run={run}
                steps={steps}
                disableOverlayClose={true}
                showSkipButton={false}
                callback={handleJoyrideCallback}
                showProgress={false}
                continuous={true}
                styles={{
                    options: {
                        primaryColor: "#13344d",
                        textColor: "#13344d",
                        zIndex: 10000,
                    },
                }}
                hideBackButton={true}
            />
        </div>
    );
}
