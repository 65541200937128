import React, { useContext } from "react";

import { useAlert } from "react-alert";
import { Link, useHistory } from "react-router-dom";

import Loader from "../components/loader";
import { UserContext } from "../contexts/user_context";
import { useApiCall } from "../hooks/api_call_hooks";
import { useBaseFetch, useProductCategories } from "../hooks/fetchHooks";
import { useObjectData } from "../hooks/test_hooks";
import {
    getIndustries,
    getOnlineStoreStatus,
    getOptions,
    updateUser,
} from "../utils/api";

export function SetUp() {
    const { user, fetchUserDetails } = useContext(UserContext);
    const history = useHistory();
    const callApi = useApiCall();
    const alert = useAlert();
    const state = useObjectData({
        count: 0,
        steps_completed: false,
        category_selection: [],
        online_store_status: null,
        industry: null,
        options: [],
    });

    const handleCategorySelection = (category) => {
        const removeIndex = state.get.category_selection.findIndex(
            (v) => v === category.id
        );
        let newValue = [];
        if (removeIndex < 0) {
            newValue = [...state.get.category_selection, category.id];
        } else {
            newValue = state.get.category_selection.filter(
                (v, i) => i !== removeIndex
            );
        }
        state.set({ category_selection: Array.from(new Set(newValue)) });
    };
    const handleOptionSelection = (option) => {
        const removeIndex = state.get.options.findIndex((v) => v === option.id);
        let newValue = [];
        if (removeIndex < 0) {
            newValue = [...state.get.options, option.id];
        } else {
            newValue = state.get.options.filter((v, i) => i !== removeIndex);
        }
        state.set({ options: Array.from(new Set(newValue)) });
    };
    const handleOnlineStoreStatusChange = (status) => {
        state.set({ online_store_status: status.id });
    };
    const handleIndustryChange = (industry) => {
        state.set({ industry: industry.id });
    };
    const updateProfile = () => {
        const data = {
            interests: state.get.category_selection,
            industry: state.get.industry,
            store_status: state.get.online_store_status,
        };
        callApi.call_api(
            () => updateUser(data),
            (data) => {
                console.log(data);
                history.push("/setup");
                alert.success(data.message);
                fetchUserDetails();
            },
            (error) => {
                console.log(error);
            }
        );
    };
    //if user has not loaded dont display the page so they wont have an idea that they are here
    if (!user?.id) {
        fetchUserDetails();
        return (
            <div>
                <Loader />
            </div>
        );
    }
    //check if user has passed through this step before and if yes push to dashboard
    else if (user.industry_id || user?.merchant?.store_status) {
        history.push("/setup");
    }

    const steps = [
        <ProductCategories
            selection={state.get.category_selection}
            handleSelection={handleCategorySelection}
        />,
        <Industries
            selection={state.get.industry}
            handleSelection={handleIndustryChange}
        />,
        <OnlineStoreStatus
            selection={state.get.online_store_status}
            handleSelection={handleOnlineStoreStatusChange}
        />,
    ];
    return (
        <section className="min-h-screen bg-white">
            {callApi.loading && <Loader />}
            <header className="flex justify-center py-6 shadow">
                <img
                    className="h-6"
                    alt=""
                    src={require("../assets/odiopay-logo-navy-blue.svg")}
                />
            </header>
            <main className="flex flex-col items-center">
                <h5 className="text-2xl font-semibold text-k2-indigo mt-10">
                    Help us get you set up faster
                </h5>
                <p className="text-k-gray text-sm mt-8 max-w-md text-center">
                    We will use the information provided to personalize your
                    experience and setup your store on Odio.
                </p>
                <div className="my-8">
                    <Counter count={state.get.count + 1} total={3} />
                </div>
                {steps[state.get.count]}
                <div className="h-40" />
                <div className="fixed bottom-0 left-0 right-0 bg-white border-t-2 py-5 pr-12 mt-6 flex-1 w-full flex justify-end items-center text-center space-x-5">
                    <Link to={"/setup"} className="text-k-indigo">
                        Skip for now
                    </Link>

                    <button
                        onClick={() => {
                            if (state.get.count < 2) {
                                state.set({ count: state.get.count + 1 });
                            } else {
                                updateProfile();
                            }
                        }}
                        className="px-8 py-3 bg-k-indigo text-white"
                    >
                        Next Step
                    </button>
                </div>
            </main>
        </section>
    );
}

function Counter({ count, total }) {
    return (
        <div className="text-center">
            <div className="text-2xl text-k6-gray mb-5">{`${count}/${total}`}</div>
            <div className="flex justify-center items-center space-x-5">
                {Array(total)
                    .fill(0)
                    .map((v, index) => (
                        <div
                            className={`border-2 w-24 ${
                                count === index + 1
                                    ? "border-k-blue"
                                    : "border-k6-gray"
                            }`}
                        />
                    ))}
            </div>
        </div>
    );
}

function ProductCategories({ selection, handleSelection }) {
    const [cat] = useProductCategories();
    const categories = cat.data || [];

    return (
        <div>
            <h6 className="text-3xl font-semibold text-k2-indigo text-center">
                Which category best describes your product or service?
            </h6>
            <p className="text-k-gray text-sm mt-2 mb-6 text-center">
                Choose the category that applies to you. You can choose more
                than one category
            </p>
            <div className="flex justify-center whitespace-no-wrap flex-wrap">
                {categories.map((category) => {
                    const active =
                        selection.findIndex((v) => v === category.id) >= 0;
                    return (
                        <div
                            key={category.id}
                            onClick={() => handleSelection(category)}
                            className={
                                (active ? "bg-k4-blue bg-opacity-25" : "") +
                                " cursor-pointer flex items-center m-4 text-k-gray text-sm border border-k2-gray rounded-sm px-5 py-4"
                            }
                        >
                            <span
                                className={
                                    (active
                                        ? "border-white"
                                        : "border-k2-gray") +
                                    " border font-bold mr-2 pt-1 inline-flex justify-center items-center h-5 w-5  rounded-full"
                                }
                            >
                                {active && (
                                    <span className="text-green-400 font-black">
                                        {" "}
                                        ✓
                                    </span>
                                )}
                            </span>
                            <span>{category.name}</span>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

function Industries({ selection, handleSelection }) {
    const [data] = useBaseFetch("", [], getIndustries);

    return (
        <div>
            <h6 className="text-3xl font-semibold text-k2-indigo text-center">
                Select the industry vertical that applies to your products and
                services
            </h6>
            <p className="text-k-gray text-sm mt-2 mb-6 text-center">
                Choose the option that applies to you
            </p>
            <div className="flex justify-center whitespace-no-wrap flex-wrap">
                {data &&
                    data.map((category) => {
                        const active = selection === category.id;
                        return (
                            <div
                                key={category.id}
                                onClick={() => handleSelection(category)}
                                className={
                                    (active ? "bg-k4-blue bg-opacity-25" : "") +
                                    " cursor-pointer flex items-center m-4 text-k-gray text-sm border border-k2-gray rounded-sm px-5 py-4"
                                }
                            >
                                <span
                                    className={
                                        (active
                                            ? "border-white"
                                            : "border-k2-gray") +
                                        " border font-bold mr-2 pt-1 inline-flex justify-center items-center h-5 w-5  rounded-full"
                                    }
                                >
                                    {active && (
                                        <span className="text-green-400 font-black">
                                            {" "}
                                            ✓
                                        </span>
                                    )}
                                </span>
                                <span>{category.name}</span>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
}

function OnlineStoreStatus({ selection, handleSelection }) {
    const [data] = useBaseFetch("", [], getOnlineStoreStatus);

    return (
        <div>
            <h6 className="text-3xl font-semibold text-k2-indigo text-center">
                Do you have an online store?
            </h6>
            <p className="text-k-gray text-sm mt-2 mb-6 text-center">
                Choose the option that applies to you
            </p>
            <div className="flex justify-center whitespace-no-wrap flex-wrap">
                {data &&
                    data.map((category) => {
                        const active = selection === category.id;
                        return (
                            <div
                                key={category.id}
                                onClick={() => handleSelection(category)}
                                className={
                                    (active ? "bg-k4-blue bg-opacity-25" : "") +
                                    " cursor-pointer flex items-center m-4 text-k-gray text-sm border border-k2-gray rounded-sm px-5 py-4"
                                }
                            >
                                <span
                                    className={
                                        (active
                                            ? "border-white"
                                            : "border-k2-gray") +
                                        " border font-bold mr-2 pt-1 inline-flex justify-center items-center h-5 w-5  rounded-full"
                                    }
                                >
                                    {active && (
                                        <span className="text-green-400 font-black">
                                            {" "}
                                            ✓
                                        </span>
                                    )}
                                </span>
                                <span>{category.name}</span>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
}

function Options({ selection, handleSelection }) {
    const [data] = useBaseFetch("", [], getOptions);

    return (
        <div className="flex flex-col justify-center">
            {data.map((category) => {
                const active =
                    selection.findIndex((v) => v === category.id) >= 0;
                return (
                    <div
                        key={category.id}
                        onClick={() => handleSelection(category)}
                        className={
                            (active ? "bg-k4-blue bg-opacity-25" : "") +
                            " cursor-pointer flex items-center m-4 max-w-lg text-k-gray text-sm border border-k2-gray rounded-sm px-5 py-4"
                        }
                    >
                        <span
                            className={
                                (active ? "border-white" : "border-k2-gray") +
                                " border font-bold mr-3 pt-1 inline-flex justify-center items-center h-5 w-5  rounded-full"
                            }
                        >
                            {active && (
                                <span className="text-green-400 font-black">
                                    {" "}
                                    ✓{" "}
                                </span>
                            )}
                        </span>
                        <div className="flex-1">
                            <h6 className="font-black text-sm mb-1">
                                {category.name}
                            </h6>
                            <p>{category.description}</p>
                        </div>
                    </div>
                );
            })}
        </div>
    );
}
